const tl = gsap.timeline();


tl.fromTo(
  '.hero__title',
  {
    rotate: -40,
    scale: 0.2,
    x: -100,
    opacity: 0,
  },
  {
    rotate:0,
    scale:1,
    x: 0,
    opacity: 1,
    duration: 0.7,
  }
)
tl.fromTo(
  '.hero__subtitle',
  {
    x: -100,
    opacity: 0,
  },
  {
    x: 0,
    opacity: 1,
    duration: 0.7,
  }
)
tl.fromTo(
  '.hero__img-box',
  {
    opacity: 0,
  },
  {
    opacity: 1,
    duration: 0.7,
  }
)
tl.fromTo(
  '.hero__text-box',
  {
    y: 200,
    opacity: 0,
  },
  {
    y: 0,
    opacity: 1,
    duration: 1.5,
  }
)

tl.fromTo(
  '.hero__descr',
  {
    x: -100,
    scale: 0.2,
    opacity: 0,
  },
  {
    x: 0,
    scale: 1,
    opacity: 1,
    duration: 0.5,
  }
)

tl.fromTo(
  '.hero__box',
  {
    rotate: -40,
    scale: 0.2,
    x: -100,
    opacity: 0,
  },
  {
    rotate:0,
    scale:1,
    x: 0,
    opacity: 1,
    duration: 1,
  }
)
tl.fromTo(
  '.frame-btn',
  {
    rotate: -40,
    scale: 0.2,
    x: -100,
    opacity: 0,
  },
  {
    rotate:0,
    scale:1,
    x: 0,
    opacity: 1,
    duration: 1,
  }
)
tl.fromTo(
  '.offer__img',
  {
    rotate: -40,
    scale: 0.2,
    x: -100,
    opacity: 0,
  },
  {
    rotate:0,
    scale:1,
    x: 0,
    opacity: 1,
    duration: 1,
  }
)
tl.fromTo(
  '.offer__title',
  {
    x: -100,
    scale: 0.2,
    opacity: 0,
  },
  {
    x: 0,
    scale: 1,
    opacity: 1,
    duration: 1.5,
  }
)
tl.fromTo(
  '.offer__price',
  {
    rotate: -40,
    scale: 0.2,
    x: -100,
    opacity: 0,
  },
  {
    rotate:0,
    scale:1,
    x: 0,
    opacity: 1,
    duration: 1,
  }
)
tl.fromTo(
  '.offer__box',
  {
    x: -100,
    scale: 0.2,
    opacity: 0,
  },
  {
    x: 0,
    scale: 1,
    opacity: 1,
    duration: 1,
  }
)






gsap.to('.offer__bottom-left', {
  scrollTrigger:{
    trgger: '.hero',
    start: 'top center',
    scrub: true,
  },
  scale: 1.4,

})

gsap.to('.services__img', {
  scrollTrigger:{
    trgger: '.price',
    start: 'top center',
    scrub: true,
  },
  scale: 0.5,

})



